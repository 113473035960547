import { defineNuxtRouteMiddleware } from '#imports';
import { useLayoutStore } from '~dsc/stores/cms/layout';
import { usePageStore } from '~dsc/stores/cms/page';
import { useCartStore } from '~dsc/stores/cms/cart';

export default defineNuxtRouteMiddleware(async to => {
    await useLayoutStore().layoutLoad();
    await usePageStore().pageLoad(to);

    if (import.meta.client) {
        await useCartStore().loadFromLocal();
    }
});
