export default {
    translateString: 'Finnish string',
    phoneNumber: 'Puhelinnumero:',
    email: 'Sähköposti:',
    open: 'Aseman aukioloajat',
    kitchen: 'A la Carte',
    lunch: 'Lounas',
    breakfast: 'Aamiainen',
    closed: 'Kiinni',
    days: {
        Mon: 'Ma',
        Tue: 'Ti',
        Wed: 'Ke',
        Thu: 'To',
        Fri: 'Pe',
        Sat: 'La',
        Sun: 'Su',
    },
    services: {
        post: 'Posti',
        servicefueling: 'Palvelutankkaus',
        adblueprivatecars: 'AdBlue',
        carmaintenance: 'Autohuolto',
        trailerrent: 'Peräkärryn vuokraus',
        matkahuolto: 'Matkahuolto',
        automatedposti: 'Posti-automaatti',
        automatedmatkahuolto: 'MH automaatti',
        veneasema: 'Venesatama',
        st1waymobiilitankkaus: 'St1 Way -mobiilitankkaus',
        nestekaasu: 'Nestekaasu',
        sauna: 'Rahtarisauna',
        shower: 'Rahtarisuihku',
        truckfuelingpoint: 'Raskaan kaluston tankkauspiste',
        atm: 'Pankkiautomaatti',
        selfservicecarwash: 'Itsepalvelupesu',
        carwash: 'Autopesu',
        perfectcarwash: 'Perfect Autopesu',
        carwash24h: 'Autopesu 24h',
        perfectcarwash24hours: 'Perfect Autopesu 24h',
        selfservicepaymentterminal: 'Pikakassa',
        veikkaus: 'Veikkaus',
        burgerking: 'Burger King',
        kotipizza: 'Kotipizza',
        lunch: 'Lounas',
        weekendlunch: 'Viikonloppulounas',
        bottlerec: 'Pullonpalautusautomaatti',
        cafeteria: 'Kahvila',
        driverspremium: 'Drivers Premium',
        goldenrax: 'Rax Pizza Buffet',
        othershop: 'Myymälä',
        vanrental: 'Pakettiauton vuokraus',
        restaurantnotconcept: 'Ravintola',
        drivers: 'Drivers -pikaruoka',
        meetingroom: 'Kokoustilat',
        freewifi: 'Ilmainen Wifi',
        pizza: 'Pizza',
        breakfastbuffet: 'Aamiaisbuffet',
        boutique: 'Myymälä',
        coffee: 'Kahvila',
        fastfood: 'Pikaruoka',
        toilet: 'Vessa',
        welcomein: 'Välkommen In',
        ploq: 'Ploq',
        laddstation: 'Latausasema',
        ecolabelcarwash: 'Ecolabel Carwash',
        dirtcarwash: 'Dirt Carwash',
        caravanparking: 'Vaunuparkki',
        alwaysonoffer: 'Aina tarjolla',
        schenker: 'Schenker-paketit',
        budbee: 'Budbee-paketit',
    },
    'lunch-list': 'Viikon lounaslista',
    'services-heading': 'Palvelut',
    food: 'HelmiSimpukka ruokalista',
    carwash: 'Perfect Autopesu',
    restaurant: 'Ravintola',
    express: 'Express',
    station: 'Asema',
    'get-directions': 'Hae reittiohjeet',
    site: {
        title: 'St1 Autopesu',
    },
};
